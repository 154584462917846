<template>
  <div v-show="showWidget" class="typesense-search-widget" :style="{ top }">
    <div class="p-3">
      <input
        type="text"
        :placeholder="`Search by ${mode} name... `"
        class="typesense-search-form"
        ref="searchField"
        v-model="searchFilter"
      />
    </div>
    <div class="px-3 pb-3">
      <div class="underline" v-if="mode === 'city'">
        {{ $t("listing.propertyInMind") }}
        <b
          class="text-main ml-2 cursor-pointer"
          @click="switchMode('property')"
          >{{ $t("listing.searchByName") }}</b
        >
      </div>
      <div class="underline" v-else>
        {{ $t("listing.visitingACity") }}
        <b class="text-main ml-2 cursor-pointer" @click="switchMode('city')">{{
          $t("listing.searchByCityName")
        }}</b>
      </div>
    </div>
    <hr class="m-0 p-0" />
    <div class="mt-2 option-list">
      <div
        v-for="result in filteredResults"
        :key="result.id"
        class="py-2 px-3 option-item"
        @click="selectDestination(result)"
      >
        <img
          class="mr-2"
          src="../../assets/icons/property.svg"
          v-if="mode === 'property'"
          alt="Property"
        />
        <img
          class="mr-2"
          src="../../assets/icons/location.svg"
          v-else
          alt="Location"
        />
        <span>{{ result.destination }}</span>
      </div>
      <Spinner v-if="searchLoading" verySmall />
      <div
        class="my-3 text-center"
        v-if="!filteredResults.length && !searchLoading"
      >
        {{ $t("listing.noResult") }}
      </div>
      <InfiniteLoading
        ref="infiniteLoading"
        @infinite="infiniteHandler"
        :identifier="infiniteId"
      >
        <div slot="spinner"></div>
        <div slot="no-results"></div>
      </InfiniteLoading>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import Spinner from "./Spinner.vue";
import InfiniteLoading from "vue-infinite-loading";
import { SearchClient as TypesenseSearchClient } from "typesense";

export default {
  name: "TypesenseSearch",
  data() {
    return {
      page: 1,
      mode: "city",
      searchFilter: "",
      showWidget: false,
      searchLoading: false,
      filteredResults: [],
      typesenseClient: null,
      filteredResultsMap: {},
      infiniteId: +new Date(),
      defaultTypesenseResult: null,
    };
  },
  components: {
    Spinner,
    InfiniteLoading,
  },
  props: {
    top: {
      type: String,
      default: "90px",
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  async created() {
    this.typesenseClient = new TypesenseSearchClient({
      nodes: [
        {
          host: process.env.VUE_APP_TYPESENSE_HOST,
          port: "",
          protocol: process.env.VUE_APP_TYPESENSE_PROTOCOL,
        },
      ],
      apiKey: process.env.VUE_APP_TYPESENSE_API_KEY,
      connectionTimeoutSeconds: 10,
    });
    this.defaultTypesenseResult = await this.typesenseResults();
  },
  methods: {
    handleClickOutside(event) {
      if (!this.$el.contains(event.target) && this.showWidget) {
        this.hide();
      }
    },
    async search(query) {
      return this.client.collections("listings").documents().search({
        q: query,
      });
    },
    show() {
      setTimeout(() => {
        this.showWidget = true;
      }, 100);
    },
    hide() {
      this.showWidget = false;
    },
    switchMode(mode) {
      this.mode = mode;
      this.resetList();
    },
    selectDestination({ destination, latitude, longitude }) {
      this.$emit("select", { destination, latitude, longitude });
      this.hide();
    },
    resetList() {
      this.page = 1;
      this.filteredResults = [];
      this.filteredResultsMap = {};
      this.defaultTypesenseResult = null;
      this.infiniteId += 1;
    },
    typesenseResults(filter = "") {
      let query_by =
        "property_cultSwitchId,property_id,property_name,property_cityName";
      let group_by = "";
      if (this.mode === "city") {
        query_by = "property_cityName";
        group_by = "property_cityName,property_countryName";
      }
      const params = {
        q: filter || "*",
        query_by,
        group_by,
        filter_by: [
          "user_id:=rootuser@roomdb.io",
          "property_status:=ACTIVE",
          "property_isDeleted:=false",
          "property_forTesting:=false",
          "property_latitude:!=0",
          "property_longitude:!=0",
          "has_geo_code:=true",
        ].join(" && "),
        page: this.page,
        per_page: 15,
        sort_by: "property_cultSwitchId:asc,property_name:asc",
      };
      return this.typesenseClient
        .collections("users_properties")
        .documents()
        .search(params);
    },
    async infiniteHandler($state) {
      const filter = this.searchFilter.trim().toLowerCase();
      this.searchLoading = true;

      let response;
      if (!filter && this.page === 1 && this.defaultTypesenseResult) {
        this.filteredResults = [];
        response = this.defaultTypesenseResult;
      } else {
        response = await this.typesenseResults(filter);
      }

      this.populateHits(response, $state, filter);

      this.searchLoading = false;
    },
    capitalize(str) {
      return str
        .split(" ")
        .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
        .join(" ");
    },
    populateHits(response, state = null, filter = "") {
      if (this.mode === "city") {
        response.grouped_hits.forEach((record) => {
          const city = this.capitalize(record.group_key[0].toLowerCase());
          if (city) {
            const country = record.group_key[1].toUpperCase();
            const destination = `${city}${
              country ? "," : ""
            } ${country}`.trim();
            const key = destination
              .split(" ")
              .join("-")
              .toLowerCase()
              .replace(",", "");
            if (!(key in this.filteredResultsMap)) {
              const hit = record.hits.find(({ document }) => {
                const { property_latitude, property_longitude } = document;
                return (
                  property_latitude &&
                  property_longitude &&
                  parseFloat(property_latitude) !== 0 &&
                  parseFloat(property_longitude) !== 0
                );
              });
              if (hit) {
                this.filteredResults.push({
                  id: key,
                  destination,
                  longitude: hit.document.property_longitude,
                  latitude: hit.document.property_latitude,
                });
                this.filteredResultsMap[key] = true;
              }
            }
          }
        });
      } else {
        response.hits.forEach((property) => {
          const {
            id,
            property_name,
            property_cityName,
            property_longitude,
            property_latitude,
          } = property.document;
          if (property_name) {
            if (!(id in this.filteredResultsMap)) {
              if (
                property_latitude &&
                property_longitude &&
                parseFloat(property_latitude) !== 0 &&
                parseFloat(property_longitude) !== 0
              ) {
                this.filteredResults.push({
                  id,
                  destination: `${property_name}${
                    property_cityName ? "," : ""
                  } ${property_cityName}`.trim(),
                  longitude: property_longitude,
                  latitude: property_latitude,
                });
                this.filteredResultsMap[id] = true;
              }
            }
          }
        });
      }

      if (response.page < response.found / 15) {
        this.page += 1;
        if (state && filter === "") {
          state.loaded();
        }
      } else if (state) {
        state.complete();
      }
    },
    handleSearch: debounce(function search() {
      this.resetList();
    }, 300),
  },
  watch: {
    defaultTypesenseResult: {
      deep: true,
      handler(response) {
        if (response) {
          this.populateHits(response);
        }
      },
    },
    searchFilter(value, oldValue) {
      if (value !== oldValue) {
        this.handleSearch();
      }
    },
    showWidget(value) {
      if (value) {
        setTimeout(() => {
          this.$refs.searchField.focus();
        }, 100);
      }
    },
  },
};
</script>

<style>
.typesense-search-widget {
  position: absolute;
  width: 100%;
  background: white;
  z-index: 9999;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 14px !important;
}

.option-list {
  max-height: 350px;
  overflow-y: auto;
}

.option-item {
  cursor: pointer;
}

.option-item img {
  width: 20px;
}

.option-item:hover {
  background: #f5ffff;
}

input.typesense-search-form {
  min-width: 100% !important;
  background: #fff !important;
  border: 1px solid #ddd !important;
  border-radius: 5px !important;
  height: 50px !important;
  font-size: 14px !important;
  color: #000 !important;
  padding: 10px;
}
input.typesense-search-form::placeholder {
  color: #888;
}

@media screen and (max-width: 600px) {
  input.typesense-search-form {
    text-align: left !important;
    padding-left: 10px !important;
  }
}
</style>
